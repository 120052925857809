<template>
  <EntityList
    :config="config"
    :columns="columns"
    :form-fields="formFields"
    authority="General_Cover_Area"
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../storeModule'

export default {
  components: {
    EntityList,
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'municipalities',
        endpoint: 'municipalities',
        route: 'municipalities',
        title: {
          single: this.$i18n.t('Municipality'),
          plural: this.$i18n.t('Municipalities'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'title', label: this.$t('title'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },
    formFields() {
      return [
        {
          id: 'title',
          type: 'text',
          label: this.$t('title'),
          required: true,
          rules: 'max:40',
        },
        {
          id: 'lat',
          type: 'text',
          label: this.$t('Lat'),
          rules: 'positive',
          required: false,
        },
        {
          id: 'lng',
          type: 'text',
          label: this.$t('Lng'),
          rules: 'positive',
          required: false,
        },
        {
          id: 'radius',
          type: 'number',
          label: this.$t('radius'),
          required: false,
        },
        {
          id: 'enabled',
          type: 'checkbox',
          label: this.$t('enabled'),
          initVal: 1,
        },
      ]
    },
  },
}
</script>
